import * as siteConfig from "./_variable";

// Toggle the visibility of the contact's details.
window.toggleContactCard = function(clickedObject) {

  let parent = clickedObject.closest('div.contact-card');
  if (parent === null) {
    return;
  }

  if (parent.classList.contains('flip')) {
    parent.classList.remove('flip');
  } else {
    document.querySelectorAll('div.flip').forEach(item => {
      item.classList.remove('flip');
    });
    parent.classList.add('flip');
  }
};

// When the user presses a key find the contact names matching the search criteria.
let contactSearchTimer = null;
window.contactSearchKeyPress = function(inputObject) {

  // Wait a short period to make sure the user has actually finished typing before we perform the search.
  clearTimeout(contactSearchTimer);
  contactSearchTimer = setTimeout(() => {

    let totalSearchResults = 0;
    let search = inputObject.value.toLowerCase().trim();

    // Iterate through all the names and show/hide them based on the given search criteria.
    document.querySelectorAll('h2.contact-name-for-search').forEach(nameHeading => {

      let parent = nameHeading.closest('div.contact-card');
      if (parent === null) {
        return;
      }

      // Also search on the job title.
      let sibling = next(nameHeading);

      if ((search === '') || (nameHeading.innerHTML.toLowerCase().indexOf(search) >= 0) || (sibling.innerHTML.toLowerCase().indexOf(search) >= 0)) {
        totalSearchResults++;
        parent.classList.remove('d-none');
      } else {
        parent.classList.add('d-none');
      }
    });

    // If there are any locations with no contacts, than we will hide these.
    document.querySelectorAll('div[data-collapsed]').forEach(location => {
      let elements = location.querySelectorAll('div.contact-card:not(.d-none)');
      if (elements.length === 0) {
        location.classList.add('d-none');
      } else {
        location.classList.remove('d-none');
        location.querySelector('h3').innerText = elements.length + ' Contact' + ((elements.length === 1) ? '' : 's');
      }
    });

    // If there are any headings that don't contain visible locations then we will hide these.
    document.querySelectorAll('.location-country-name').forEach(heading => {

      let sibling = next(heading);
      let elements = sibling.querySelectorAll('div[data-collapsed]:not(.d-none)');

      if (elements.length === 0) {
        heading.classList.add('d-none');
      } else {
        heading.classList.remove('d-none');
      }
    });

  }, 250);
};

// Returns the HTML for the contact.
window.styleContact = function(contact) {

  let domain = window.location.hostname.slice(4);

  // We had to hard code BMA because email and domain name was different.
  if (siteConfig.api.company === 'BMA') {
    domain = 'benmarine.com'
  }

  let at = contact.email.indexOf('@');
  let staff = contact.email.substr(0, at+1);
  let newEmail = staff + domain;

  let email = '';
  if (contact.email !== '') {
    email = ' \
      <div class="mb-2"> \
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> \
          <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/> \
          <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/> \
        </svg> \
        <a href="mailto:' + newEmail + '">Send Email</a> \
      </div>';
  }

  let telephone = '';
  if (contact.phone_raw !== '') {
    telephone = ' \
      <div class="mb-2"> \
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> \
          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/> \
        </svg> \
        <a href="tel:' + contact.phone_raw + '">' + contact.phone_parsed + '</a> \
      </div>';
  }

  let mobile = '';
  if (contact.mobile_raw !== '') {
    mobile = ' \
      <div> \
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> \
          <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/> \
          <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/> \
        </svg> \
        <a href="tel:' + contact.mobile_raw + '">' + contact.mobile_parsed + '</a> \
      </div>';
  }


  return ' \
    <div class="col-12 col-sm-6 col-md-4 col-xl-3 text-center contact-card"> \
      <div class="contact-card-avatar"> \
        <div class="flex-grow-1"> \
          <img onerror="showPlaceholderImage(this);" class="contact-avatar" src="' + siteConfig.api.domain + 'person_avatar/' + contact.id + '.jpg?referrer=' + siteConfig.api.referrer + '" alt="' + contact.firstname + ' ' + contact.lastname + '"> \
          <h2 class="contact-name-for-search">' + contact.firstname + ' ' + contact.lastname + '</h2> \
          <h3 class="contact-position-for-search">' + contact.position + '</h3> \
        </div> \
        <button onclick="toggleContactCard(this);" class="mt-2 btn btn-sm btn-outline-primary">Contact ' + contact.firstname + '</button> \
      </div> \
      \
      <div class="contact-details"> \
        <div class="contact-details-heading"> \
          <div> \
            <h2>' + contact.firstname + ' ' + contact.lastname + '</h2> \
            <h3>' + contact.position + '</h3> \
          </div> \
        </div> \
        \
        <div class="contact-details-data"> \
          <div class="flex-grow-1"> \
            ' + email + ' \
            ' + telephone + ' \
            ' + mobile + ' \
          </div> \
          \
          <button onclick="toggleContactCard(this);" class="mt-2 btn btn-sm btn-outline-primary">Close</button> \
        </div> \
      </div> \
    </div>';
};

// Run the following when the page has finished loading.
ready(() => {

  // Retrieve all the contacts from our API, iterate through them to find the one we need to display information about.
  let contactContainer = document.querySelector('#contact-container');
  if (contactContainer !== null) {

    // Start by showing a loading spinner, users on slow conditions will see that content is being loaded.
    contactContainer.innerHTML = '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';

    // Declare the variable, the global key is used for the directors and the head office.
    let data = { 'Global': { 'Directors': { 'members': [] }, 'Head Office': { 'members': [] } } };
    let members = [];

    // Retrieve a list with all the directors.
    // Also added if statements to remove directors from other companies.
    fetchApiData(siteConfig.api.domain + 'team_by_id/' + siteConfig.companyBoardTeamId + '?referrer=' + siteConfig.api.referrer).then(team => {
      if (siteConfig.api.referrer == 'Graypen' || (siteConfig.api.referrer == 'GP Shipping')) {
        team.members.forEach(member => {
          if (!members.includes(member.id)) {
            members.push(member.id);
            data['Global']['Directors']['members'].push(member);
          }
        });
      } else {
        data =[];
      }

      // Retrieve all the contacts from the API. Because we're displaying the contacts by location, we can use the
      // same API call as we do for the locations page, which should be nice and speedy as it's cached.
      fetchApiData(siteConfig.api.domain + 'locations_grouped/' + siteConfig.api.company + '/true?referrer=' + siteConfig.api.referrer).then(countries => {
        countries.forEach(country => {

          // We split the locations by country, make sure the object key exists.
          if (!data.hasOwnProperty(country.group_name)) {
            data[country.group_name] = {};
          }

          country.locations.forEach(location => {

            // If this location doesn't handle any ports we hide it.
            if (location.ports.length === 0) {
              return;
            }

            location.teams.forEach(team => {

              // If there isn't an object yet for the found location, then create it so we can append html to it.
              if ((team.physical_location !== '') && !data[country.group_name].hasOwnProperty(team.physical_location)) {
                data[country.group_name][team.physical_location] = { 'members': [] };
              }

              // Add the members of this team to the data object, make sure to leave duplicates out.
              team.members.forEach(member => {
                if (!members.includes(member.id)) {
                  members.push(member.id);
                  if (team.physical_location === '') {
                    data['Global']['Head Office']['members'].push(member);
                  } else {
                    data[country.group_name][team.physical_location]['members'].push(member);
                  }
                }
              });
            });
          });
        });

        if (typeof contactsPreProcessor === 'function') {
          data = contactsPreProcessor(data);
        }

        /**
         * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
         * WE HAVE ALL THE DATA, CONSTRUCT IT INTO HTML AND PRINT TO SCREEN.
         * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
         */

        let categories = Object.keys(data);
        categories.forEach(category => {

          // Make sure that we clear the loading spinner, if present.
          if (contactContainer.innerHTML.indexOf('lds-ellipsis') >= 0) {
            contactContainer.replaceChildren();
          }

          let locations = Object.keys(data[category]);
          let output = '<h2 class="location-country-name">' + category + '</h2><div>';
          locations.sort().forEach(location => {

            if (data[category][location]['members'].length === 0) {
              return;
            }

            // We don't sort the members of the directors group.
            if (location === 'Head Office') {
              data[category][location]['members'].sort((a, b) => (a.firstname > b.firstname) ? 1 : -1);
            }

            // Build the HTML for the members at each location.
            let members = '';
            data[category][location]['members'].forEach(member => {
              members += styleContact(member);
            });

            // Apply correct photo to container.
            let local_logo = '';
            if (category === 'Netherlands' || category === 'Belgium') {
              local_logo = '<img class="d-none position-absolute end-0 local-logo me-2" src="' + siteConfig.logos[category.toLowerCase()] + '">';
            }

            // Add the generated html to the dom.
            output += ' \
              <div class="d-flex flex-column pt-2 pb-2 contactChild" onclick="toggleLocationItem(this);" data-collapsed="true"> \
                <div class="d-flex flex-row align-items-center position-relative"> \
                  <div class="px-3"> \
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 16 16"> \
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> \
                    </svg> \
                    \
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="d-none" viewBox="0 0 16 16"> \
                      <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/> \
                    </svg> \
                  </div> \
                  \
                  <div class="pe-3"> \
                    <h2 class="m-0 p-0">' + location + '</h2> \
                    <h3 class="m-0 p-0 ports-covered">' + data[category][location]['members'].length + ' Contacts</h3> \
                  </div> \
                  ' + local_logo + '\
                </div> \
                \
                <div class="d-none collapsed-container mt-2 local-logo" onclick="event.stopPropagation();"> \
                  <div class="row w-100 p-2 m-0"> \
                    ' + members + ' \
                  </div> \
                </div> \
              </div>';
          });

          contactContainer.insertAdjacentHTML('beforeend', output + '</div>');
        });

        let location = document.querySelectorAll('#contact-container .contactChild');
        if (location.length === 1) {
          location[0].click();
        }

      }).catch(() => {
        contactContainer.innerHTML = '<div class="col-12 text-danger">Something went wrong, we couldn\'t load our list of contacts. Please try again in a few minutes.</div>';
      });
    }).catch(() => {
      contactContainer.innerHTML = '<div class="col-12 text-danger">Something went wrong, we couldn\'t load our list of contacts. Please try again in a few minutes.</div>';
    });
  }
});