import * as siteConfig from "./_variable";

/**
 * Populate the services menu item. Used for the footer.
 * @param container
 */
window.constructServicesMenu = function(container) {

  document.querySelectorAll(container).forEach ((servicesMenuItem) => {

    // Retrieve all the services from our API, iterate through them constructing the html, and add this to the dom.
    fetchApiData(siteConfig.api.domain + 'services/' + siteConfig.api.company + '?referrer=' + siteConfig.api.referrer).then(services => {
      services.forEach(service => {
        let servicePageObject = {
          'href': '/services.html#',
          'button': '<button onclick="toggleServiceCard(this);" class="btn btn-sm btn-outline-secondary text-nowrap">Find Out More</button>',
          'html': ''
        };
        
        if (typeof servicesPageUrlProcessor === 'function') {
          servicePageObject = servicesPageUrlProcessor(service);
        }

        servicesMenuItem.insertAdjacentHTML('beforeend', '<a href="' + servicePageObject.href + slugify(service.name) + servicePageObject.html +'" class="nav-item">' + service.name + '</a>');
      });
    }).catch(() => {

      // We're deleting the menu item as we couldn't finish loading it.
      let parent = servicesMenuItem.closest('.nav-item');
      if (parent !== null) {
        let separator = prev(parent, '.nav-separator');
        if (separator !== undefined) {
          separator.remove();
        }

        parent.remove();
      }
    });
  })
};

// Toggle the visibility of the service's details.
window.toggleServiceCard = function(clickedObject) {

  let parent = clickedObject.closest('div.service-container');
  if (parent === null) {
    return;
  }

  if (parent.classList.contains('flip')) {
    parent.classList.remove('flip');
  } else {
    document.querySelectorAll('div.flip').forEach(item => {
      item.classList.remove('flip');
    });
    parent.classList.add('flip');
  }
};

// Scripts required for the website, which require the dom to be fully loaded.
ready(() => {

  /**
   * OUR SERVICES COMPONENT:
   * The services handled are pulled in via the API. But only if there is a services component present on the page.
   */
  let servicesComponent = document.querySelector('#our-services-component');
  if (servicesComponent !== null) {

    // Find the container in which we have to add all our services.
    let servicesDestinationContainer = servicesComponent.querySelector('.row');
    if (servicesDestinationContainer !== null) {

      // Show a loading spinner.
      servicesDestinationContainer.innerHTML = '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';

      // Get the company data, this will tell us how many services we need to show per line.
      let accaptedServicesPerRow = [1, 2, 3, 4, 6];
      fetchApiData(siteConfig.api.domain + 'companies/' + siteConfig.api.company + '?referrer=' + siteConfig.api.referrer).then(company => {
        let servicesPerRow = 4;
        if (accaptedServicesPerRow.indexOf(company.service_items_per_row) !== -1) {
          servicesPerRow = company.service_items_per_row;
        }

        // Retrieve all the services from our API, iterate through them constructing the html, and add this to the dom.
        fetchApiData(siteConfig.api.domain + 'services/' + siteConfig.api.company + '?referrer=' + siteConfig.api.referrer).then(services => {
          services.forEach(service => {
            let serviceStyle = { 'text': '', 'logo': '' };
            if (typeof servicesPreProcessor === 'function') {
              serviceStyle = servicesPreProcessor(service);
            }

            // Make sure that we clear the loading spinner, if present.
            if (servicesDestinationContainer.innerHTML.indexOf('lds-ellipsis') >= 0) {
              servicesDestinationContainer.replaceChildren();
            }

            let email = '';
            if (service.generic_email !== '') {
              email = ' \
                <div class="mt-1"> \
                  <svg class="smallIcon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> \
                    <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/> \
                    <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/> \
                  </svg> \
                  <a ' + serviceStyle.text + ' href="mailto:' + service.generic_email + '">Send Email</a> \
                </div>';
            }

            let telephone = '';
            if (service.generic_telephone !== '') {
              telephone = ' \
                <div class="mt-1"> \
                  <svg class="smallIcon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> \
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/> \
                  </svg> \
                  <a ' + serviceStyle.text + ' href="tel:' + service.generic_telephone_raw + '">' + service.generic_telephone + '</a> \
                </div>';
            }

            let website = '';
            if (service.website !== '') {
              website = ' \
                <div class="mt-1"> \
                  <svg class="smallIcon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> \
                    <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/> \
                    <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/> \
                  </svg> \
                  <a ' + serviceStyle.text + ' href="' + service.website + '" target="_blank">Visit Website</a> \
                </div>';
            }

            const tinycolor = require('tinycolor2');
            let serviceColour = ((service.color.length === 6) ? ('#' + service.color) : getCssVariable('--primary-color'));
            let gradientColour = tinycolor(serviceColour).darken(25).toString();

            let emblemImage = '';
            if (serviceStyle.logo !== '') {
              emblemImage = '<img id="sister-emblem" src="' + serviceStyle.logo + '" alt="sister-company-logo">';
            }

            let servicePageObject = {
              'href': '/services.html#',
              'button': '<button onclick="toggleServiceCard(this);" class="btn btn-sm btn-outline-secondary text-nowrap">Find Out More</button>',
              'html': ''
            };

            if (typeof servicesPageUrlProcessor === 'function') {
              servicePageObject = servicesPageUrlProcessor(service);
            }

            // Add a new service container to the dom.
            servicesDestinationContainer.insertAdjacentHTML('beforeend', ' \
            <div id="' + slugify(service.name) + '" class="col-12 col-sm-6 col-md-4 col-xl-' + (12 / servicesPerRow) + '"> \
              <div class="service-container"> \
                <div class="service-front"> \
                  <div class="top" style="background-image: url(\'' + siteConfig.api.domain + 'service_photo/' + service.id + '.jpg?referrer=' + siteConfig.api.referrer + '\');"></div> \
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 150" preserveAspectRatio="none" fill="url(#serviceBackground' + service.id + ')" stroke="none"> \
                    <defs> \
                      <linearGradient id="serviceBackground' + service.id + '" x1="0" x2="0" y1="0" y2="1"> \
                        <stop offset="0%" stop-color="' + gradientColour + '" /> \
                        <stop offset="100%" stop-color="' + serviceColour + '" /> \
                      </linearGradient> \
                    </defs> \
                    <path d="M320,0l-144.498,0l-15.46,20.306l-15.371,-20.306l-144.671,0l0,150l320,-0l0,-150Z" /> \
                  </svg> \
                  <div class="bottom"> \
                    <span class="text-uppercase">' + service.name + '</span> \
                    ' + servicePageObject.button + ' \
                  </div> \
                </div> \
                \
                <div class="service-back"> \
                  <div class="service-back-details-heading position-relative"> \
                    <h2 class="service-name" ' + serviceStyle.text + '>' + service.name + '</h2> \
                    ' + email + ' \
                    ' + telephone + ' \
                    ' + website + ' \
                    ' + emblemImage + '\
                  </div> \
                  \
                  <div class="service-back-description"> \
                    ' + service.description.replaceAll('\n', '<br>') + ' \
                  </div> \
                  \
                  <div class="service-back-button"> \
                    <button onClick="toggleServiceCard(this);" class="btn btn-sm btn-outline-primary">Close</button> \
                  </div> \
                </div> \
              </div> \
            </div>');
          });
        }).catch((error) => {
          console.log(error);
          servicesDestinationContainer.innerHTML = '<div class="col-12 text-danger">Something went wrong, we couldn\'t load our list of services. Please try again in a few minutes.</div>';
        });
      }).catch((error) => {
        console.log(error);
        servicesDestinationContainer.innerHTML = '<div class="col-12 text-danger">Something went wrong, we couldn\'t load our list of services. Please try again in a few minutes.</div>';
      });
    }
  }
});