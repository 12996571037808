import * as siteConfig from "./_variable";

/**
 * The Google Maps Library is loading asynchronous, and so are the locations.
 * To make sure we don't get a race conditions the library will set this boolean to true, thus we know that
 * the google maps retrieved by the locations api can be initialised.
 */
let googleScriptInitialised = false;
window.initGoogleMap = function() {
  googleScriptInitialised = true;
};

/**
 * Close the given location object.
 *
 * @param object
 */
window.closeLocationObject = function(object) {
  let previousToggleableObject = object.lastElementChild;
  previousToggleableObject.classList.add('d-none');
  object.setAttribute('data-collapsed', 'true');
  object.querySelector('svg:first-child').classList.remove('d-none');
  object.querySelector('svg:last-child').classList.add('d-none');
  object.classList.remove('mb-3');
  object.classList.add('pb-2');
  object.querySelector('.local-logo').classList.add('d-none');
};

/**
 * Collapse or expand the location item based on its current state.
 * @param clickedObject
 */
let currentlyOpenedItem = null;
window.toggleLocationItem = function(clickedObject) {
  let toggleableObject = clickedObject.lastElementChild;

  // Return if the toggleable object doesn't exist
  if (toggleableObject === null) {
    return;
  }

  // Close the previously opened item
  if (currentlyOpenedItem !== null && currentlyOpenedItem !== clickedObject) {
    closeLocationObject(currentlyOpenedItem);
  }

  if (clickedObject.getAttribute('data-collapsed') === 'true') {
    // Open the clicked item
    toggleableObject.classList.remove('d-none');
    clickedObject.setAttribute('data-collapsed', 'false');
    clickedObject.querySelector('svg:first-child').classList.add('d-none');
    clickedObject.querySelector('svg:last-child').classList.remove('d-none');
    clickedObject.classList.add('mb-3');
    clickedObject.classList.remove('pb-2');
    clickedObject.querySelector('.local-logo').classList.remove('d-none');

    // Find the map container and initialize it
    let map = clickedObject.querySelector('.office-locations-map');
    if (map !== null) {
      let icon = siteConfig.locations.mapMarkerImage;
      if (typeof mapPreProcessor === 'function') {
        icon = mapPreProcessor(clickedObject);
      }

      initOfficeLocation(map, icon);
    }

    // If present, click the first department
    let firstLi = toggleableObject.querySelector('li');
    if (firstLi !== null) {
      toggleDepartmentItem(firstLi);
    }

    // Set the currently opened item to the clicked item
    currentlyOpenedItem = clickedObject;
  } else {
    // Close the clicked item
    closeLocationObject(clickedObject);
    
    // Set the currently opened item to null
    currentlyOpenedItem = null;
  }
};



// Show the selected department's information.
window.toggleDepartmentItem = function(clickedObject, scrollIntoView) {

  let parentContainer = clickedObject.closest('div');
  if (parentContainer === null) {
    return;
  }

  // We have to disable all previous active elements. We can only show one.
  parentContainer.querySelectorAll('li').forEach(li => {
    li.classList.remove('active');
  });

  // Set the current clicked object to active.
  clickedObject.classList.add('active');

  // Grab the container in which we're going to display the department information.
  let departmentContainer = next(parentContainer);
  if (departmentContainer === null) {
    return;
  }

  if (scrollIntoView) {
    departmentContainer.scrollIntoView();
  }

  // The data has already been fetched when loading the locations page, we will re-use it here so it's super speedy.
  fetchApiData(siteConfig.api.domain + 'locations_grouped/' + siteConfig.api.company + '/true?referrer=' + siteConfig.api.referrer).then(countries => {
    countries.forEach(country => {
      country.locations.forEach(location => {
        location.teams.forEach(team => {

          // Make sure we grab the correct team.
          if (team.id === parseInt(clickedObject.getAttribute('data-id'))) {

            departmentContainer.innerHTML = '<h4 class="mb-1">Contacts:</h4>';

            // Iterate through all the members in this team and show their face.
            team.members.forEach(member => {

              departmentContainer.insertAdjacentHTML('beforeend', ' \
                <div class="d-flex align-items-center my-2"> \
                  <img onerror="showPlaceholderImage(this);" class="me-2 avatar" src="' + siteConfig.api.domain + 'person_avatar/' + member.id + '.jpg?referrer=' + siteConfig.api.referrer + '" alt="' + member.firstname + ' ' + member.lastname + '" /> \
                  <span>' + member.firstname + ' ' + member.lastname + '</span> \
                </div>');

            });

            // Add the telephone and email buttons if available.
            if (team.telephone !== '') {
              departmentContainer.insertAdjacentHTML('beforeend', '<a href="tel:' + team.telephone_raw + '" class="btn btn-outline-primary text-start overflow-hidden mw-100 text-uppercase me-3 mt-3">Phone: ' + team.telephone + '</a>');
            }

            if (team.email !== '') {
              departmentContainer.insertAdjacentHTML('beforeend', '<a href="mailto:' + team.email + '" class="btn btn-outline-primary text-start overflow-hidden mw-100 text-uppercase me-3 mt-3">Email: ' + team.email + '</a>');
            }
          }
        });
      });
    });
  });
};

/**
 * Initialise the map on the expanded location. We do this only once and only when the library was successfully loaded.
 */
window.initOfficeLocation = function(map, icon) {

  // If the Google library hasn't loaded yet, we will set a timer to try again at a later time.
  if (!googleScriptInitialised) {
    setTimeout(() => {
      initOfficeLocation(map, icon);
    }, 200);
    return;
  }

  // Grab the office coordinates from the container to which the map will be added.
  const coords = {
    lat: parseFloat(map.getAttribute('data-lat')),
    lng: parseFloat(map.getAttribute('data-lng'))
  };

  // Create a new map and center it onto where our office is located.
  let mapObject = new google.maps.Map(map, {
    center: coords,
    zoom: 12,
    disableDefaultUI: true,
    styles: [{
      'featureType': 'poi',
      'stylers': [{'visibility': 'off'}]
    }]
  });

  // Place our own custom marker as defined in the variable file onto the map to show where the office is located.
    var marker = new google.maps.Marker({
      position: coords,
      map: mapObject,
      icon: icon
    });
  };


// When the user presses a key find the port names matching the search criteria.
let locationSearchTimer = null;
window.locationSearchKeyPress = function(inputObject) {

  // Wait a short period to make sure the user has actually finished typing before we perform the search.
  clearTimeout(locationSearchTimer);
  locationSearchTimer = setTimeout(() => {

    let totalSearchResults = 0;
    let search = inputObject.value.toLowerCase().trim();

    // Iterate through all the locations and show/hide them based on the given search criteria.
    document.querySelectorAll('h3.ports-covered').forEach(location => {

      let parent = location.closest('div[data-collapsed]');
      if (parent === null) {
        return;
      }

      if ((search === '') || (location.innerHTML.toLowerCase().indexOf(search) >= 0)) {
        totalSearchResults++;
        parent.classList.remove('d-none');
      } else {
        parent.classList.add('d-none');
      }
    });

    // If there was any search results then we hide the global coverage, as we only show it when there is no search result
    // or if nothing was found for a given search criteria.
    let globalCoverage = document.getElementById('locations-global-coverage');
    if (globalCoverage !== null) {
      if ((search === '') || (totalSearchResults === 0)) {
        globalCoverage.classList.remove('d-none');
      } else {
        globalCoverage.classList.add('d-none');
      }
    }

    // If there are any headings that don't contain visible locations then we will hide these.
    document.querySelectorAll('.location-country-name').forEach(heading => {

      let sibling = next(heading);
      let elements = sibling.querySelectorAll('div[data-collapsed]:not(.d-none)');

      if (elements.length === 0) {
        heading.classList.add('d-none');
      } else {
        heading.classList.remove('d-none');
      }
    });

  }, 250);
};

// Run the following when the page has finished loading.
ready(() => {

  // Retrieve all the locations from our API, iterate through them to find the one we need to display information about.
  let locationsContainer = document.querySelector('#locations-container');
  if (locationsContainer !== null) {

    // Start by showing a loading spinner, users on slow conditions will see that content is being loaded.
    locationsContainer.innerHTML = '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';

    // Retrieve all the locations from the API.
    fetchApiData(siteConfig.api.domain + 'locations_grouped/' + siteConfig.api.company + '/true?referrer=' + siteConfig.api.referrer).then(countries => {

      if (typeof locationsPreProcessor === 'function') {
        countries = locationsPreProcessor(countries);
      }

      countries.forEach(country => {

        // Make sure that we clear the loading spinner, if present.
        if (locationsContainer.innerHTML.indexOf('lds-ellipsis') >= 0) {
          locationsContainer.replaceChildren();
        }

        if (country.locations.length > 0) {

          let output = '<h2 class="location-country-name">' + country.group_name + '</h2><div>';

          country.locations.forEach(location => {

            // The API was buggy and showing teams of 0 which weren't allocated but still appeared as a location, this removes it.
            if(location.teams.length === 0) {
              return;
            }

            // If this location doesn't handle any ports we hide it.
            if (location.ports.length === 0) {
              return;
            }

            // If this location has any teams we construct the html for these first, so it can all be added to the dom together.
            let teams = '';
            if (location.teams.length) {
              location.teams.forEach(team => {
                teams += ' \
                  <li onclick="toggleDepartmentItem(this, true);" data-id="' + team.id + '"> \
                    <span class="text-truncate">' + team.name +'</span> \
                    <svg class="mx-2 d-none" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16"> \
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/> \
                    </svg> \
                  </li>';
              });

              teams = '<h4 class="mt-3 mb-1 pe-3">Departments at ' + location.location + '</h4><ul class="mb-0 mb-md-3">' + teams + '</ul>';
            }


            // Add a Netherlands or Belgium logo.
            let local_logo = '';
            if ((country.group_name === 'Belgium') || (country.group_name === 'Netherlands')) {
              local_logo = '<img class="d-none position-absolute end-0 local-logo me-2 mb-1" src="' + siteConfig.logos[country.group_name.toLowerCase()] + '">';
            }

            // Andy wanted a different wording for GPL Customs so made this if statement to change it.
            let covers_ports = '<h3 class="m-0 p-0 ports-covered">Covers: ' + location.ports.join(', ') + '</h3> ';
            let ports_covered = '<h4 class="mt-3 mb-1 pe-3">Ports Covered</h4>';

            if (typeof coversPortsPreProcessor === 'function') {
              covers_ports = coversPortsPreProcessor(location);
            }

            if (typeof portsCoveredPreProcessor === 'function') {
              ports_covered = portsCoveredPreProcessor();
            }


            // Add the generated html to the dom.
            output += ' \
              <div class="d-flex flex-column pt-2 pb-2 locationChild" onclick="toggleLocationItem(this);" data-collapsed="true"> \
                <div class="d-flex flex-row align-items-center position-relative"> \
                  <div class="px-3"> \
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 16 16"> \
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> \
                    </svg> \
                    \
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="d-none" viewBox="0 0 16 16"> \
                      <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/> \
                    </svg> \
                  </div> \
                  \
                  <div class="pe-3"> \
                    <h2 class="m-0 p-0">' + location.location + (location.head_office ? ' (Head Office)' : '') + '</h2> \
                   ' + covers_ports + ' \
                  </div> \
                  ' + local_logo + ' \
                </div> \
                \
                <div class="d-none collapsed-container mt-2 local-logo" onclick="event.stopPropagation();"> \
                  <div \
                    class="office-locations-map" \
                    id="office-location-' + location.id + '" \
                    data-lat="' + location.lat + '" \
                    data-lng="' + location.lng + '" \
                  ></div> \
                  \
                  <div class="py-3 ps-3 pe-3 pe-md-0"> \
                    <h4 class="mb-1 pe-3">Address:</h4> \
                    <span class="pe-3">' + location.address + '</span> \
                    ' + ports_covered + '\
                    <span class="pe-3">' + location.ports.join(', ') + '</span> \
                    ' + teams + ' \
                  </div> \
                  \
                  <div class="py-3 px-3"></div> \
                </div> \
              </div>';

          });

          locationsContainer.insertAdjacentHTML('beforeend', output + '</div>');
        }
      });

      let location = document.querySelectorAll('#locations-container .locationChild');
      if (location.length === 1) {
        location[0].click();
      }

      // A company could also have a global coverage section, this would be like a HUB department using local agents to cover ports.
      fetchApiData(siteConfig.api.domain + 'companies/' + siteConfig.api.company + '?referrer=' + siteConfig.api.referrer).then(company => {

        // We only will add the global coverage if it's given on the company level.
        if ((company.global_coverage.length <= 0) || (company.global_coverage.members.length <= 0)) {
          return;
        }

        // Iterate through all the members in this team and show their face.
        let people = '';
        company.global_coverage.members.forEach(member => {

          people += ' \
            <div class="d-flex align-items-center my-2"> \
              <img onerror="showPlaceholderImage(this);" class="me-2 avatar" src="' + siteConfig.api.domain + 'person_avatar/' + member.id + '?referrer=' + siteConfig.api.referrer + '" alt="' + member.firstname + ' ' + member.lastname + '" /> \
              <span>' + member.firstname + ' ' + member.lastname + '</span> \
            </div>';

        });

        locationsContainer.insertAdjacentHTML('beforeend', '<h2 class="location-country-name">Global Coverage</h2>');

        // Add the generated html to the dom.
        locationsContainer.insertAdjacentHTML('beforeend', ' \
          <div> \
            <div id="locations-global-coverage" class="d-flex flex-column pt-2 pb-2" onclick="toggleLocationItem(this);" data-collapsed="true"> \
              <div class="d-flex flex-row align-items-center"> \
                <div class="px-3"> \
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 16 16"> \
                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> \
                  </svg> \
                  \
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="d-none" viewBox="0 0 16 16"> \
                    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/> \
                  </svg> \
                </div> \
                \
                <div class="pe-3"> \
                  <h2 class="m-0 p-0">World Wide</h2> \
                  <h3 class="m-0 p-0">Covers: All ports</h3> \
                </div> \
              </div> \
              \
              <div class="d-none collapsed-container mt-2 justify-content-end" onclick="event.stopPropagation();"> \
                <div class="py-3 ps-3"> \
                  <h4 class="mb-1 pe-3">Address:</h4> \
                  <span class="pe-3">' + company.global_coverage.location_address + '</span> \
                  <h4 class="mt-3 mb-1 pe-3">Departments</h4> \
                  <ul> \
                    <li class="active"> \
                        <span class="text-truncate">' + company.global_coverage.team_name + '</span> \
                        <svg class="mx-2 d-none" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16"> \
                          <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/> \
                        </svg> \
                      </li> \
                  </ul> \
                </div> \
                \
                <div class="py-3 px-3"> \
                  <h4 class="mb-1">Contacts:</h4> \
                  ' + people + ' \
                  <a href="tel:' + company.global_coverage.team_telephone_raw + '" class="btn btn-outline-primary text-start overflow-hidden mw-100 text-uppercase me-3 mt-3">Phone: ' + company.global_coverage.team_telephone + '</a> \
                  <a href="mailto:' + company.global_coverage.team_email + '" class="btn btn-outline-primary text-start overflow-hidden mw-100 text-uppercase me-3 mt-3">Email: ' + company.global_coverage.team_email + '</a> \
                </div> \
              </div> \
            </div> \
          </div>');

      });

    }).catch((error) => {
      console.log(error);
      locationsContainer.innerHTML = '<div class="col-12 text-danger">Something went wrong, we couldn\'t load our list of locations. Please try again in a few minutes.</div>';
    });
  }
});